import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Apology`}</h2>
    <p>{`First of all I would like to apologise for not posting this week's article and the previous week's newsletter but I was too much engrossed into this site and coding and I couldn't make time for writing, This site is finally on Gatsby!! Now I am taking a break from all things coding. I will still be writing articles but there probably won't be any new features on this site. The next addition to this site will probably be a search bar which searches through the names of all the articles. This week's articles will be on books and how reading helps me live happier and smarter. Now that this is out of the way, lets get into the content`}</p>
    <h2>{`Weekly favourites`}</h2>
    <p><strong parentName="p">{`Software`}</strong>{` - Visual Studio Code. I have been coding for some time now and I was
starting to be a Sublime Text fanboy. I still used VS Code but it wasn't doing the
job for me. But now.. things have changed. I don't even use Sublime Text anymore
and now I use VS Code. I am mainly a ReactJS guy and this website is built using
GatsbyJS which is powered by ReactJS and the experience of coding React in VS Code
is leaps and bounds ahead than that of Sublime.`}</p>
    <p><strong parentName="p">{`More Software`}</strong>{` - So as you know from my quote of weeks, I use Readwise.io. I used to like it for sure but I wasn't blown away as I thought I would be. But now it actually has blown my mind. I new that you could do exports from Readwise to Roam Research, Evernote and Notion, but I didn't think that this will be so revolutionary. I thought that Readwise would just copy my highlights from my Kindle account and paste it into my Roam database. But oh boy was I wrong. And I am glad to be wrong. Let me explain how it pleasantly surprised me. Readwise, sends you your highlights from books, and articles(and now even podcasts! at least if you are on IOS). You can tag them as your favourite, or make custom tags. When you sync your highlights to Roam, it creates a page in Roam with the name of the book concatenated with '(highlights)'. In this page it copies and pastes your highlights along with their locations in the book. So if you click on the location it automatically jumps to that place in the book. Now here is the cool part. It nests your tags in side your highlights. Here, see this`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "356px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/386be112177970a8d1f82bfe24383d55/50ac3/readwise-note.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVQI153MWwqDMBCFYfe/sUK7hrbEXMQ0iiYamkT8DfrYtw58DJxhTiOkxH7cSWmDEJK2lShj6K3lXe9Ka6RWvESLUIZxnOhcQPQzxnm09TyV45sKTYyRYRhxlQ8Lsw8sa6xWSink6tw514dESplt22r+a9/3q9CYjtv9wTDNhFrml8s/cwDFHOZQXFjShQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "readwise-note",
            "title": "readwise-note",
            "src": "/static/386be112177970a8d1f82bfe24383d55/50ac3/readwise-note.png",
            "srcSet": ["/static/386be112177970a8d1f82bfe24383d55/772e8/readwise-note.png 200w", "/static/386be112177970a8d1f82bfe24383d55/50ac3/readwise-note.png 356w"],
            "sizes": "(max-width: 356px) 100vw, 356px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Ask for Forgiveness, Not Permission is my highlight and it has its location right
beside it as a link. And nested inside are the tags associated with it through the
Readwise App!!`}</p>
    <h2>{`Quote of the week`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/28b1bafe16a65eff10e5e286c7aef21f/798d4/Quote-3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABj0lEQVQoz6VSiU7CQBT0340JJkpMwYhR/8NElMOonHIjWMQWAQVpS++D0vGxKhHFxMRNZt+8PWZnd99GEAT4T/u+f2PROYYAU84SinD1Emz1Fq5WYlhwSylCl/LwaM6UCzApN+QcPJNfin4KM8GhcIHKVQh3hSiERgz17B6aBQ695iFaxQjuS1Ga45Z4rMdQutxCNXeyXtDW7uAqcbjTFCw5AUdJMm4Td6dJGK/nLFrSBVw1hZmWgG/fw5+pWOj8EBT5OPLJEMrXHKoZDvVchJwegS8fkNP9d+SjlMcgNo/JMQdp1PxwN//5hq7RptPPyF2SuZtpaXhqmnFzkljmi2jTGk9NYO4NVq674rDbOkUhtYnKTRiV6x3UMrvotw7pLcMoX1FOkS9zbLyWCaOR3YYuV3936Nlj6EobxrQDW3+gX+/CVHk4xC2twzB3nxgCrw/fERH4+u9lI0kKnvrPeH55hdgb4GU0gSAOMBrLeBT6GA7Hf6rBpaA/82AZGmxTh2MZDLT861Z2tVUEa0XfAMJ16gMqptxxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Quote",
            "title": "Quote",
            "src": "/static/28b1bafe16a65eff10e5e286c7aef21f/5a190/Quote-3.png",
            "srcSet": ["/static/28b1bafe16a65eff10e5e286c7aef21f/772e8/Quote-3.png 200w", "/static/28b1bafe16a65eff10e5e286c7aef21f/e17e5/Quote-3.png 400w", "/static/28b1bafe16a65eff10e5e286c7aef21f/5a190/Quote-3.png 800w", "/static/28b1bafe16a65eff10e5e286c7aef21f/798d4/Quote-3.png 976w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Tweet of the week`}</h2>
    <blockquote className="twitter-tweet" data-dnt="true">
  <p lang="el" dir="ltr">
    π <a href="https://t.co/HjQ05PqSDa">pic.twitter.com/HjQ05PqSDa</a>
  </p>
  &mdash; Grant Sanderson (@3blue1brown) <a href="https://twitter.com/3blue1brown/status/1315022219307749376?ref_src=twsrc%5Etfw">October 10, 2020</a>
    </blockquote>
    <h2>{`Books that I will be finishing this week`}</h2>
    <center>{`
  `}<a parentName="center" {...{
        "href": "https://www.amazon.com/Case-against-Education-System-Waste-ebook/dp/B07T3QRNLC/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1602387002&sr=1-1"
      }}>{`
    `}<img parentName="a" {...{
          "src": "https://m.media-amazon.com/images/I/51lHQvZOZiL._SY346_.jpg",
          "className": "img-fluid",
          "style": {},
          "width:": "",
          "\"221px\",": "",
          "height:": "",
          "\"346px\",": "",
          "}}": ""
        }}></img>{`
  `}</a></center>
    <undefined><hr></hr>{`
  `}<a {...{
        "href": "https://www.amazon.com/4-Hour-Work-Week-Escape-Anywhere-ebook/dp/B006X0M2TS/ref=sr_1_1?crid=284DTAJEK48GJ&dchild=1&keywords=the+four+hour+work+week&qid=1602387150&s=digital-text&sprefix=The+Four+hour+wor%2Cdigital-text%2C205&sr=1-1"
      }}>{`
    `}<img parentName="a" {...{
          "src": "https://m.media-amazon.com/images/I/51iGkLC6jhL.jpg",
          "className": "img-fluid",
          "style": {},
          "width:": "",
          "\"221px\",": "",
          "height:": "",
          "\"346px\",": "",
          "}}": ""
        }}></img>{`
  `}</a>
    </undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      